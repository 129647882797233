var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Bạn phải thành công đơn trước khi đối soát",
        visible: _vm.visible,
        width: "1200px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: function($event) {
          return _vm.$emit("submit:success", _vm.dataTable)
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("span", { staticClass: "font-bold font-size--large" }, [
          _c("i", { staticClass: "el-icon-warning color-yellow\n" }),
          _vm._v(" Bạn phải thành công đơn trước khi đối soát")
        ])
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.transformTable, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "STT", type: "index", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { label: "Id đơn hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          href:
                            "https://v1.nhanh.vn/order/manage/detail?storeId=84054&id=" +
                            row.orderId,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" " + _vm._s(row.orderId) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customerName" }
          }),
          _c("el-table-column", {
            attrs: { label: "Trả shop" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            row.calcTotalMoney - row.shipFee
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Ship" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.shipFee)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "font-bold",
                        class:
                          row.statusCode == "New"
                            ? "color-red"
                            : "color-primary"
                      },
                      [_vm._v(_vm._s(row.statusName))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24, offset: 0 } }, [
            _c(
              "div",
              { staticStyle: { float: "right" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { type: "primary", loading: _vm.loadingButton },
                    on: { click: _vm.handleSuccessOrder }
                  },
                  [_vm._v("Thành công đơn hàng")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { type: "primary", loading: _vm.loadingButton },
                    on: { click: _vm.handleSuccessV1Order }
                  },
                  [_vm._v("Thành công v1")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }