"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saleConfigApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var saleConfigApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/saleConfig',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/saleConfig',
      method: 'POST',
      data: data
    });
  },
  update: function update(data) {
    return (0, _request.default)({
      url: "/saleConfig/update",
      method: 'POST',
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/saleConfig/".concat(id, "/delete"),
      method: 'POST'
    });
  }
};
exports.saleConfigApi = saleConfigApi;