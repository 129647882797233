"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productApi = {
  findAllConfig: function findAllConfig(params) {
    return (0, _request.default)({
      url: '/product/config',
      params: params
    });
  },
  findAllImport: function findAllImport(params) {
    return (0, _request.default)({
      url: '/product/import',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/product',
      method: 'POST',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/product/".concat(id, "/update"),
      method: 'POST',
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/product/".concat(id, "/delete"),
      method: 'POST'
    });
  }
};
exports.productApi = productApi;