var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết tour",
        visible: _vm.visible,
        width: "1200px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", [
        _c("div", { staticClass: "summary-form" }, [
          _c("label", [_vm._v("Thời gian ")]),
          _c("span", { staticClass: "retail-price font-bold " }, [
            _vm._v(": " + _vm._s(_vm.data.tour && _vm.data.tour.time))
          ])
        ]),
        _c("div", { staticClass: "summary-form" }, [
          _c("label", [_vm._v("Số đơn ")]),
          _c("span", { staticClass: "retail-price font-bold " }, [
            _vm._v(": " + _vm._s(_vm.data.orderIds && _vm.data.orderIds.length))
          ])
        ]),
        _c("div", { staticClass: "summary-form" }, [
          _c("label", [_vm._v("Tiền ứng ")]),
          _c("span", { staticClass: "retail-price font-bold " }, [
            _vm._v(
              ": " +
                _vm._s(
                  _vm.$formatNumberVN(
                    _vm.data.tour && _vm.data.tour.moneyReturnShop
                  )
                )
            )
          ])
        ]),
        _c("div", { staticClass: "summary-form" }, [
          _c("label", [_vm._v("Tiền ship ")]),
          _c("span", { staticClass: "retail-price font-bold " }, [
            _vm._v(": " + _vm._s(_vm.$formatNumberVN(_vm.data.shipFeePersonal)))
          ])
        ]),
        _c("hr"),
        _vm.data.tour && _vm.data.tour.dataTable.length
          ? _c(
              "div",
              _vm._l(_vm.data.tour.dataTable, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { "padding-left": "20px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "summary-form",
                        staticStyle: { "margin-left": "10px" }
                      },
                      [
                        _c("label", [_vm._v("Đơn ")]),
                        _c("span", { staticClass: "retail-price font-bold " }, [
                          _vm._v(": " + _vm._s(index + 1))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "summary-form" }, [
                      _c("label", [_vm._v("Tên ")]),
                      _c("span", { staticClass: "retail-price font-bold " }, [
                        _vm._v(": " + _vm._s(item.customerName))
                      ])
                    ]),
                    _c("div", { staticClass: "summary-form" }, [
                      _c("label", [_vm._v("Địa chỉ ")]),
                      _c("span", { staticClass: "retail-price font-bold " }, [
                        _vm._v(
                          ": " +
                            _vm._s(item.customerAddress) +
                            ", " +
                            _vm._s(item.customerDistrict) +
                            ", " +
                            _vm._s(item.customerCity)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "summary-form" }, [
                      _c("label", [_vm._v("Tiền ứng ")]),
                      _c("span", { staticClass: "retail-price font-bold " }, [
                        _vm._v(
                          ": " +
                            _vm._s(
                              _vm.$formatNumberVN(
                                item.calcTotalMoney - item.shipFee
                              )
                            )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "summary-form" }, [
                      _c("label", [_vm._v("Tiền ship ")]),
                      _c("span", { staticClass: "retail-price font-bold " }, [
                        _vm._v(": " + _vm._s(_vm.$formatNumberVN(item.shipFee)))
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }