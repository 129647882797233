var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          href:
                            "https://v1.nhanh.vn/product/item/detail?storeId=84054&id=" +
                            row.idNhanh,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" " + _vm._s(row.name) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Hạn mức", prop: "quantityStock" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: true,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                        }
                      ],
                      attrs: { placeholder: "", size: "normal" },
                      on: {
                        change: function($event) {
                          return _vm.pasteEventLimit($event, row)
                        }
                      },
                      model: {
                        value: row.productConfig.limit,
                        callback: function($$v) {
                          _vm.$set(row.productConfig, "limit", $$v)
                        },
                        expression: "row.productConfig.limit"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: function($event) {
            return _vm.$emit("fetchData")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }