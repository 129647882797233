"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.filter.js");

require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/core-js/modules/es.array.iterator.js");

require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/core-js/modules/es.promise.js");

require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/core-js/modules/es.object.assign.js");

require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/core-js/modules/es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));

var _locale = _interopRequireDefault(require("element-ui/lib/locale"));

var _moment = _interopRequireDefault(require("moment"));

require("moment/locale/vi");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

require("@/plugins/html-to-paper");

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/plugins/element");

require("@/plugins/vue-phone-number-input");

require("./icons");

require("./permission");

var _i18n = _interopRequireDefault(require("@/plugins/i18n"));

require("@/plugins/input-mask");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _date = require("@/utils/date");

var _format = require("@/utils/format");

var _index2 = _interopRequireDefault(require("@/components/NoData/index.vue"));

var _PanelForm = _interopRequireDefault(require("@/components/Form/PanelForm.vue"));

var _index3 = _interopRequireDefault(require("@/components/Pagination/index.vue"));

var filters = _interopRequireWildcard(require("./filters"));

require("@/plugins/font-awesome-icon");

// a modern alternative to CSS resets
// without this line it didn't work
_moment.default.locale('vi');

_locale.default.use(_vi.default);

_vue.default.component('no-data', _index2.default);

_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$secondToMinutes = _format.secondToMinutes;

_vue.default.component('panel-form', _PanelForm.default);

_vue.default.component("Pagination", _index3.default);

_vue.default.use(_elementUi.default, {
  size: "small"
});

_vue.default.use(_vueClipboard.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.config.productionTip = false;
Object.defineProperty(_vue.default.prototype, "$tstore", {
  get: function get() {
    return this.$store;
  },
  enumerable: true
});
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  i18n: _i18n.default,
  render: function render(h) {
    return h(_App.default);
  }
});