var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: {
                  icon: "el-icon-search",
                  placeholder: "Tìm theo mã PO,..."
                },
                nativeOn: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.query.page = 1
                    _vm.fetchData()
                  }
                },
                model: {
                  value: _vm.query.search,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search"
                }
              })
            ],
            1
          )
        ]),
        _c("div")
      ]),
      _c("POTable", {
        attrs: {
          data: _vm.transformTable,
          query: _vm.query,
          loading: _vm.loading,
          total: _vm.total
        },
        on: { fetchData: _vm.fetchData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }