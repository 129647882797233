"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _vue = _interopRequireDefault(require("vue"));

var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");

var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");

var _vueFontawesome = require("@fortawesome/vue-fontawesome");

_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faCheck, _freeSolidSvgIcons.faEye, _freeSolidSvgIcons.faPeopleCarry, _freeSolidSvgIcons.faBuilding, _freeSolidSvgIcons.faUser, _freeSolidSvgIcons.faMoneyBill, _freeSolidSvgIcons.faDollarSign);

_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);