var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
        fullscreen: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "left",
            rules: _vm.rules,
            size: "normal"
          }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    size: "medium",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.fetchProducts,
                    disabled:
                      _vm.importStatus == _vm.ImportProductStatus.Complete
                  },
                  on: { change: _vm.handleSelect },
                  model: {
                    value: _vm.form.productId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "productId", $$v)
                    },
                    expression: "form.productId"
                  }
                },
                _vm._l(_vm.products, function(item) {
                  return _c("el-option", {
                    key: item.idNhanh,
                    attrs: { label: "" + item.name, value: item.idNhanh }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { "margin-top": "20px" },
              attrs: { data: _vm.transformTable, fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "Sản phẩm", prop: "name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href:
                                "https://v1.nhanh.vn/product/item/detail?storeId=84054&id=" +
                                row.idNhanh,
                              target: "_blank"
                            }
                          },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Tồn" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatNumberVN(
                                row.idNhanh == 25778502
                                  ? row.quantityRemain
                                  : row.quantityStock
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Số lượng nhập" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: true,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                            }
                          ],
                          attrs: {
                            disabled:
                              _vm.importStatus ==
                              _vm.ImportProductStatus.Complete,
                            size: "normal"
                          },
                          on: {
                            change: function($event) {
                              return _vm.pasteEventQuantity($event, row)
                            }
                          },
                          model: {
                            value: row.quantityImport,
                            callback: function($$v) {
                              _vm.$set(row, "quantityImport", $$v)
                            },
                            expression: "row.quantityImport"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Đơn giá" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: true,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                            }
                          ],
                          attrs: {
                            disabled:
                              _vm.importStatus ==
                              _vm.ImportProductStatus.Complete,
                            size: "normal"
                          },
                          on: {
                            change: function($event) {
                              return _vm.pasteEventAmount($event, row)
                            }
                          },
                          model: {
                            value: row.amount,
                            callback: function($$v) {
                              _vm.$set(row, "amount", $$v)
                            },
                            expression: "row.amount"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Thành tiền" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$formatNumberVN(row.total)) + "đ "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Thao tác" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.importStatus == _vm.ImportProductStatus.New
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Xóa",
                                  placement: "top",
                                  effect: "dark"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(row, $index)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tổng tiền" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: true,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                          }
                        ],
                        attrs: { disabled: "", size: "normal" },
                        model: {
                          value: _vm.totalMoney,
                          callback: function($$v) {
                            _vm.totalMoney = $$v
                          },
                          expression: "totalMoney"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thời gian giao hàng" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled:
                            _vm.importStatus ==
                            _vm.ImportProductStatus.Complete,
                          "value-format": "timestamp",
                          format: "dd/MM/yyyy",
                          type: "date",
                          placeholder: "Chọn ngày"
                        },
                        model: {
                          value: _vm.form.dateDelivery,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dateDelivery", $$v)
                          },
                          expression: "form.dateDelivery"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nhà cung cấp" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              _vm.importStatus ==
                              _vm.ImportProductStatus.Complete,
                            clearable: "",
                            size: "medium",
                            filterable: ""
                          },
                          model: {
                            value: _vm.form.providerId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "providerId", $$v)
                            },
                            expression: "form.providerId"
                          }
                        },
                        _vm._l(_vm.providers, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: "" + item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.status == "update"
                ? _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Trạng thái" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                size: "medium",
                                filterable: ""
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status"
                              }
                            },
                            _vm._l(_vm.ImportProductStatus, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: _vm.ImportProductStatusTrans[item],
                                  value: item
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function($event) {
                    _vm.visible = false
                  }
                }
              },
              [_vm._v("Huỷ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                attrs: { type: "primary", loading: _vm.loadingSubmit },
                on: {
                  click: function($event) {
                    _vm.status == "create" ? _vm.createData() : _vm.updateData()
                  }
                }
              },
              [_vm._v("Xác nhận")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }