var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "timestamp",
                    format: "dd/MM/yyyy",
                    type: "month",
                    size: "medium",
                    placeholder: "Chọn ngày",
                    clearable: false
                  },
                  on: {
                    change: function($event) {
                      return _vm.fetchData()
                    }
                  },
                  model: {
                    value: _vm.query.date,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "date", $$v)
                    },
                    expression: "query.date"
                  }
                })
              ],
              1
            ),
            _vm.$store.state.user.token
              ? _c(
                  "el-button",
                  {
                    staticClass: "filter-item btn-save",
                    staticStyle: { transform: "translateY(0px)" },
                    attrs: { type: "primary", loading: _vm.loadingButton },
                    on: { click: _vm.fetchOrderInMonth }
                  },
                  [_vm._v(" Cập nhật danh sách đơn ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.transformTable, fit: "" }
        },
        [
          _c("el-table-column", { attrs: { label: "Tên", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Tỉ trọng doanh số (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(row.ratioTotalSale * 100, 2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉ trọng số đơn (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(row.ratioTotalOrder * 100, 2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉ lệ hủy đơn (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(row.ratioCancelOrder * 100, 2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Độ chuyên cần (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(row.ratioWorkDays * 100, 2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Điểm số (%)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.total * 100, 2)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết",
                          placement: "top",
                          effect: "dark"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "success",
                            size: "mini",
                            icon: "el-icon-view"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleView(row)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("detail-report", { ref: "detailReport" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }