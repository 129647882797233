var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách",
        visible: _vm.visible,
        width: "1200px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: { data: _vm.transformTable, fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã sản phẩm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.code) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tổng SL đặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.totalQuantityImport)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tổng SL giao thực tế" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.totalQuantityDelivery)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _vm.form.status != _vm.ImportProductStatus.Complete
            ? _c("el-table-column", {
                attrs: { label: "Thao tác" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Cập nhật",
                                placement: "top",
                                effect: "dark"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  icon: "el-icon-edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit(row)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Xóa",
                                placement: "top",
                                effect: "dark"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-delete"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(row)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  741257185
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function($event) {
                    _vm.visible = false
                  }
                }
              },
              [_vm._v("Đóng")]
            )
          ],
          1
        )
      ]),
      _c("import-modal", {
        ref: "importModal",
        on: { "submit:success": _vm.fetchDataUpdate }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }