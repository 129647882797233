var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.dataTable,
            border: "",
            stripe: "",
            loading: _vm.loading
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "STT", type: "index", width: "70" }
          }),
          _c("el-table-column", { attrs: { label: "Sale", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Sale Nhanh Id", prop: "saleNhanhId" }
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Xóa",
                          placement: "top",
                          effect: "dark"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            size: "mini",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(row)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }