"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saleApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var saleApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: '/sale'
    });
  },
  report: function report(params) {
    return (0, _request.default)({
      url: '/sale/report',
      params: params
    });
  },
  reportDetail: function reportDetail(id, params) {
    return (0, _request.default)({
      url: "/sale/".concat(id, "/report"),
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/sale',
      method: 'POST',
      data: data
    });
  },
  updateAll: function updateAll(data) {
    return (0, _request.default)({
      url: "/sale/update",
      method: 'POST',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/sale/".concat(id, "/update"),
      method: 'POST',
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/sale/".concat(id, "/delete"),
      method: 'POST'
    });
  }
};
exports.saleApi = saleApi;