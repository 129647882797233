"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productConfigApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var productConfigApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/productConfig',
      params: params
    });
  },
  update: function update(data) {
    return (0, _request.default)({
      url: "/productConfig/update",
      method: 'POST',
      data: data
    });
  },
  updateAll: function updateAll(data) {
    return (0, _request.default)({
      url: "/productConfig/update/all",
      method: 'POST',
      data: data
    });
  }
};
exports.productConfigApi = productConfigApi;