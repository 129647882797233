"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _vue = _interopRequireDefault(require("vue"));

var _vuePhoneNumberInput = _interopRequireDefault(require("vue-phone-number-input"));

require("vue-phone-number-input/dist/vue-phone-number-input.css");

_vue.default.component('vue-phone-number-input', _vuePhoneNumberInput.default);