var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.data,
            fit: "",
            "default-sort": { prop: "quantityTotal", order: "ascending" }
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection" } }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "product.name", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          href:
                            "https://v1.nhanh.vn/product/item/detail?storeId=84054&id=" +
                            row.idNhanh,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" " + _vm._s(row.name) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Có thể bán", sortable: "", prop: "quantityStock" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(
                            row.idNhanh == 25778502
                              ? row.quantityRemain
                              : row.quantityStock
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Đã lên lịch",
              sortable: "",
              prop: "quantityImport"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "" },
                        on: {
                          click: function($event) {
                            row.quantityImport && _vm.onClickRow(row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$formatNumberVN(row.quantityImport)) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Kế hoạch đã giao",
              sortable: "",
              prop: "quantityTotal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "font-bold",
                        class:
                          row.checkLimit == _vm.ImportStatus.LowLimit
                            ? "color-red"
                            : row.checkLimit == _vm.ImportStatus.NearLimit
                            ? "color-orange"
                            : ""
                      },
                      [_vm._v(_vm._s(_vm.$formatNumberVN(row.quantityTotal)))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: function($event) {
            return _vm.$emit("fetchData")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }