var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "forgot-password" }, [
        _vm._v("Reset your password")
      ]),
      _c("p", { staticClass: "content" }, [
        _vm._v(
          " Enter your registered login email address to receive a secure link to set a new password "
        )
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Email address" } },
            [
              _c("el-input", {
                attrs: {
                  size: "medium",
                  placeholder: "Enter your email address"
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "btn-reset",
          attrs: { type: "primary", size: "" },
          on: { click: function($event) {} }
        },
        [_vm._v("Reset password")]
      ),
      _c(
        "div",
        { staticClass: "text-center", staticStyle: { "margin-top": "16px" } },
        [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("back-login")
                }
              }
            },
            [_vm._v("Back go login")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }