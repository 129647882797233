"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.reset = reset;
exports.findOne = findOne;
exports.updatePassword = updatePassword;
exports.getList = getList;
exports.add = add;
exports.update = update;
exports.remove = remove;
exports.logout = logout;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: "/user/login",
    method: "post",
    data: data
  });
}

function getInfo() {
  return (0, _request.default)({
    url: "/user/profile",
    method: "get"
  });
}

function reset(id, data) {
  return (0, _request.default)({
    url: "/staff/".concat(id, "/password/reset"),
    method: "post",
    data: data
  });
}

function findOne(userId) {
  return (0, _request.default)({
    url: "/staff/".concat(userId),
    method: "get"
  });
}

function updatePassword(data) {
  return (0, _request.default)({
    url: "/auth/profile/password/update",
    method: "post",
    data: data
  });
}

function getList(listQuery) {
  return (0, _request.default)({
    url: "/staff",
    method: "get",
    params: listQuery
  });
}

function add(data) {
  return (0, _request.default)({
    url: "/staff",
    method: "post",
    data: data
  });
}

function update(userId, data) {
  return (0, _request.default)({
    url: "/staff/".concat(userId, "/update"),
    method: "post",
    data: data
  });
}

function remove(userId) {
  return (0, _request.default)({
    url: "/staff/".concat(userId, "/delete"),
    method: "post"
  });
}

function logout() {
  return (0, _request.default)({
    url: "/user/logout",
    method: "post"
  });
}