var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết import",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        fullscreen: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.transformTable,
            fit: "",
            "default-sort": { prop: "quantityTotal", order: "descending" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Có thể bán", sortable: "", prop: "quantityStock" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantityStock)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Đã lên lịch",
              sortable: "",
              prop: "quantityImport"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.quantityImport)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Đơn giá", sortable: "", prop: "amount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + "đ ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Thành tiền", sortable: "", prop: "total" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.$formatNumberVN(row.total)) + "đ ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "NCC",
              sortable: "",
              prop: "importProduct.provider.name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.importProduct.provider.name) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Tồn + kế hoạch",
              sortable: "",
              prop: "quantityTotal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantityTotal)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thời gian dự kiến",
              sortable: "",
              prop: "dateLeft"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDateDuration(row.dateLeft)) + " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: function($event) {
            return _vm.$emit("fetchData")
          }
        }
      }),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function($event) {
                    _vm.visible = false
                  }
                }
              },
              [_vm._v("Đóng")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }