var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item btn-save",
                staticStyle: { transform: "translateY(0px)" },
                attrs: {
                  type: "primary",
                  size: "medium",
                  icon: "el-icon-plus"
                },
                on: { click: _vm.handleUpdate }
              },
              [_vm._v(" Cập nhật ")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Nhân viên", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.name) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉ lệ huỷ đơn", prop: "ratioCancelOrder" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: true,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                        }
                      ],
                      attrs: { placeholder: "", size: "normal" },
                      on: {
                        change: function($event) {
                          return _vm.pasteEventRatioCancelOrder($event, row)
                        }
                      },
                      model: {
                        value: row.ratioCancelOrder,
                        callback: function($$v) {
                          _vm.$set(row, "ratioCancelOrder", $$v)
                        },
                        expression: "row.ratioCancelOrder"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }