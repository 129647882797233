var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-container" }, [
    _c(
      "div",
      { staticClass: "form-container" },
      [
        _vm.status == _vm.StatusPage.LoginEmail
          ? _c("login-form", {
              on: { "chang-status-page": _vm.handleChangeStatus }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }