"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nextChar = nextChar;

function nextChar(c) {
  var numberNext = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return String.fromCharCode(c.charCodeAt(0) + numberNext);
}