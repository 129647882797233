var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết shipper " + _vm.name,
        visible: _vm.visible,
        width: "1200px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.dataTable,
            border: "",
            stripe: "",
            loading: _vm.loading
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "STT", type: "index", width: "70" }
          }),
          _c("el-table-column", { attrs: { label: "Ngày", prop: "date" } }),
          _c("el-table-column", {
            attrs: { label: "Thời gian", prop: "time" }
          }),
          _c("el-table-column", {
            attrs: { label: "Số đơn hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.orders.length)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Id đơn hàng", prop: "totalOrder" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return _vm._l(row.orders, function(item, index) {
                    return _c(
                      "p",
                      { key: index },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href:
                                "https://v1.nhanh.vn/order/manage/detail?storeId=84054&id=" +
                                item.orderId,
                              target: "_blank"
                            }
                          },
                          [_vm._v(" " + _vm._s(item.orderId) + " ")]
                        )
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tiền ship" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toThousandFilter")(row.moneyShipFeePersonal)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }