"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nhanhApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var nhanhApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/nhanh/order',
      params: params,
      timeout: 100000
    });
  },
  productCategory: function productCategory() {
    return (0, _request.default)({
      url: '/nhanh/product/category'
    });
  },
  findAllProducts: function findAllProducts(params) {
    return (0, _request.default)({
      url: '/nhanh/product',
      params: params
    });
  },
  updateProducts: function updateProducts(data) {
    return (0, _request.default)({
      url: '/nhanh/product',
      method: 'post',
      data: data
    });
  },
  findAllOrders: function findAllOrders(params) {
    return (0, _request.default)({
      timeout: 3 * 60 * 1000,
      url: '/nhanh/orders',
      params: params
    });
  }
};
exports.nhanhApi = nhanhApi;