"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/my_project/vietbox-barcode/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var authApi = {
  login: function login(data) {
    return (0, _request.default)({
      url: '/auth/login',
      data: data,
      method: 'post'
    });
  },
  getInfo: function getInfo() {
    return (0, _request.default)({
      url: "/auth/profile",
      method: "get"
    });
  }
};
exports.authApi = authApi;